import React from "react"
import PropTypes from "prop-types"
import { useInView } from 'react-intersection-observer'

const propTypes = {
  className: PropTypes.string,
  onClassName: PropTypes.string,
  offClassName: PropTypes.string,
  onSrc: PropTypes.string.isRequired,
  offSrc: PropTypes.string.isRequired,
  alt: PropTypes.string,
}

const HistoryImgInview = ({
  className,
  onClassName,
  offClassName,
  onSrc,
  offSrc,
  alt
}) => {
  const [ref, inView] = useInView({
    rootMargin: '-40% 0px',
    triggerOnce: true
  })
  return (
    <>
      <span ref={ref} data-view={inView} className={className}>
        <img src={onSrc} className={onClassName} alt={alt ? alt : ""}/>
        <img src={offSrc} className={offClassName} alt={alt ? alt : ""}/>
      </span>
    </>
  )
}

HistoryImgInview.propTypes = propTypes
export default HistoryImgInview