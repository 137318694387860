import React from "react"
import PropTypes from "prop-types"

import styles from '@styles/components/history_tl_section.module.scss'


const propTypes = {
  year: PropTypes.number.isRequired,
  events: PropTypes.arrayOf(PropTypes.shape(
    {
      month: PropTypes.string.isRequired,
      itms: PropTypes.arrayOf(PropTypes.node).isRequired
    }
  )).isRequired
}
const HistoryTlSection = ({
  year,
  events
}) => {

  const eventsElm = events.map( (event, index) => {
    return (
      <li key={`${year}-${index}`} className={styles.event}>
        <h2 className={styles.event__month}>{event.month}</h2>
        <ol className={styles.event__box}>
          {
            event.itms.map( (itm, i) => {
              return (
                <li key={`${year}-${index}-${i}`} className={styles.event__itm}>{itm}</li>
              )
            })
          }
        </ol>
      </li>
    )
  })

  return (
    <>
      <section className={styles.year}>
        <h1 className={styles.year__title}>{year}</h1>
        <ol className={styles.year__body}>
          {eventsElm}
        </ol>
      </section>
    </>
  )
}

HistoryTlSection.propTypes = propTypes
export default HistoryTlSection