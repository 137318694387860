import React,{useMemo} from "react"
import { graphql } from "gatsby"

import styles from '@styles/pages/news.module.scss'

import Layout from "@comp/layout"
import SEO from '@comp/seo'

import TransitionAnimationLink from '@comp/transition_animation_link'

import ContactBlock from '@comp/contact_block'
import PageTitle from '@comp/page_title'
import HistoryTlSection from '@comp/history_tl_section'
import HistoryImgInview from '@comp/history_img_inview'

// markup
const NewsPage = ({ 
  location,
  data
}) => {

  const recentNewsPots = useMemo( () => {
    return data.allMarkdownRemark.edges?.map( (post) => {
      return {
        ...post.node.frontmatter,
        datetime: post.node.frontmatter.date.replace(/\//g,'-')
      }
    });
  },[data.allMarkdownRemark.edges])

  const recentNewsListElm = useMemo( () => {
    return recentNewsPots.map( (post, index) => {
      return (
        <article key={`news_${index}`} className={styles.news__post}>
          <TransitionAnimationLink to={ post.slug } className={styles.news__post__link}>
            <div className={styles.news__post__img}>
              <img src={ post.thumbnailImg } alt=""/>
            </div>
            <h1 className={styles.news__post__title}>{ post.title }</h1>
            <time className={styles.news__post__date}>{ post.date }</time>
          </TransitionAnimationLink>
        </article>
      )
    })
  },[recentNewsPots])

  return (
    <Layout location={location} pageName="news">
      <SEO
        title={`取り組み`}
        description={`MIRAI SAKE COMPANYに関する最新の取り組み、および創業以来の実績をご紹介しています。`}
        path={location.pathname}
      />
      <main className={styles.container}>
        <div className={styles.inner}>
          <PageTitle
            label='取り組み'
            labelEn='NEWS'
          />

          <section className={styles.recent}>
            <h1 className={styles.recent__title}>最新の取り組み</h1>
            <div className={styles.recent__list}>
              { recentNewsListElm }
            </div>
          </section>

          <section id="history" className={styles.history}>
            <h1 className={styles.history__title}>これまでの歩み</h1>
            <div className={styles.history__timeline}>
              <HistoryTlSection 
                year={2013}
                events={[
                  {
                    month: '09',
                    itms: [
                      (
                        <>
                          会社設立。コンサルティング事業及びPR事業をスタート。
                        </>
                      )
                    ]
                  }
                ]}
              />
              <HistoryTlSection 
                year={2015}
                events={[
                  {
                    month: '01',
                    itms: [
                      (
                        <>
                          日本酒のカッコいいを楽しむ大人の遊び場「KURA FES」をスタート
                        </>
                      )
                    ]
                  },
                  {
                    month: '02',
                    itms: [
                      (
                        <>
                          委託醸造によるMIRAI SAKE BREWING事業をスタート（現在までに24アイテムを開発・展開）
                        </>
                      )
                    ]
                  },
                  {
                    month: '03',
                    itms: [
                      (
                        <>
                          小売事業及び通信販売小売事業をスタート
                          <HistoryImgInview
                            className={styles.history__itm__img}
                            onClassName={styles.history__itm__img__on}
                            offClassName={styles.history__itm__img__off}
                            onSrc="/images/news/history_201503.jpg"
                            offSrc="/images/news/history_201503_off.jpg"
                          />
                        </>
                      )
                    ]
                  }
                ]}
              />
              <HistoryTlSection 
                year={2017}
                events={[
                  {
                    month: '04',
                    itms: [
                      (
                        <>
                          未来のスター蔵元が集う新時代型SAKEイベント「SAKE STAR FES」を開催
                        </>
                      )
                    ]
                  },
                  {
                    month: '02',
                    itms: [
                      (
                        <>
                          委託醸造によるMIRAI SAKE BREWING事業をスタート（現在までに24アイテムを開発・展開）
                        </>
                      )
                    ]
                  },
                  {
                    month: '03',
                    itms: [
                      (
                        <>
                          SAKEセレクトショップ「未来日本酒店」事業をスタート
                          <HistoryImgInview
                            className={styles.history__itm__img}
                            onClassName={styles.history__itm__img__on}
                            offClassName={styles.history__itm__img__off}
                            onSrc="/images/news/history_201706.jpg"
                            offSrc="/images/news/history_201706_off.jpg"
                          />
                        </>
                      )
                    ]
                  }
                ]}
              />

              <HistoryTlSection 
                year={2018}
                events={[
                  {
                    month: '04',
                    itms: [
                      (
                        <>
                          AI×SAKEの日本酒テイスティングエンターテインメント「YUMMY SAKE」事業をスタート
                          <HistoryImgInview
                            className={styles.history__itm__img}
                            onClassName={styles.history__itm__img__on}
                            offClassName={styles.history__itm__img__off}
                            onSrc="/images/news/history_201804.jpg"
                            offSrc="/images/news/history_201804_off.jpg"
                          />
                        </>
                      )
                    ]
                  },
                  {
                    month: '07',
                    itms: [
                      (
                        <>
                          未来日本酒店 KICHIJOJI（東京都武蔵野市）をオープン
                        </>
                      )
                    ]
                  }
                ]} 
              />

              <HistoryTlSection 
                year={2019}
                events={[
                  {
                    month: '08',
                    itms: [
                      (
                        <>
                          未来酒蔵承継機構を開始
                        </>
                      )
                    ]
                  },
                  {
                    month: '11',
                    itms: [
                      (
                        <>
                          未来日本酒店 & SAKE BAR（東京都渋谷区）をオープン
                          <HistoryImgInview
                            className={styles.history__itm__img}
                            onClassName={styles.history__itm__img__on}
                            offClassName={styles.history__itm__img__off}
                            onSrc="/images/news/history_201911.jpg"
                            offSrc="/images/news/history_201911_off.jpg"
                          />
                        </>
                      )
                    ]
                  }
                ]} 
              />

              <HistoryTlSection 
                year={2020}
                events={[
                  {
                    month: '08',
                    itms: [
                      (
                        <>
                          日本酒専門ライブコマース「CHEERSAKE」事業をスタート
                          <HistoryImgInview
                            className={styles.history__itm__img}
                            onClassName={styles.history__itm__img__on}
                            offClassName={styles.history__itm__img__off}
                            onSrc="/images/news/history_202009.jpg"
                            offSrc="/images/news/history_202009_off.jpg"
                          />
                        </>
                      )
                    ]
                  },
                  {
                    month: '11',
                    itms: [
                      (
                        <>
                          未来日本酒店 & SAKE BAR（東京都渋谷区）をオープン
                          <HistoryImgInview
                            className={styles.history__itm__img}
                            onClassName={styles.history__itm__img__on}
                            offClassName={styles.history__itm__img__off}
                            onSrc="/images/news/history_202012.jpg"
                            offSrc="/images/news/history_202012_off.jpg"
                          />
                        </>
                      )
                    ]
                  }
                ]} 
              />

              <HistoryTlSection 
                year={2021}
                events={[
                  {
                    month: '05',
                    itms: [
                      (
                        <>
                          未来酒店 FUTAKO-TAMAGAWA（東京都世田谷区）をオープン
                          <HistoryImgInview
                            className={styles.history__itm__img}
                            onClassName={styles.history__itm__img__on}
                            offClassName={styles.history__itm__img__off}
                            onSrc="/images/news/history_202104.jpg"
                            offSrc="/images/news/history_202104_off.jpg"
                          />
                        </>
                      )
                    ]
                  }
                ]} 
              />
            </div>
          </section>


          <section className={styles.contact}>
            <ContactBlock
              copy={'次の取組みをご一緒しませんか？'}
              txt={'私たちの活動に関心を持っていただけましたら、下記フォームよりお問い合わせください。'}
            />
          </section>
        </div>
      </main>
    </Layout>
  )
}

export default NewsPage

export const pageQuery = graphql`
  {
    allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {category: {eq: "news"}}}, limit: 3) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            date(formatString: "YYYY/MM/DD")
            thumbnailImg
          }
        }
      }
    }
  }
`